._53Ji7 {
  padding: 2rem;
}
._53Ji7, ._53Ji7 * {
  box-sizing: border-box;
}
._1Lo2h {
  margin: 0 0 1rem 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
._2Jtxm {
  list-style: none;
  position: relative;
  text-align: center;
  flex: 1 1 auto;
  position: relative;
}

._2Jtxm::before {
  content: '';
  position: absolute;
  top: 50%;
  height: 3px;
  width: 100%;
  right: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
  background: linear-gradient(to left, #e7eaf3 50%, #4db193 50%);
	background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 200ms ease-in-out;
}
._2Jtxm:first-child::before {
  content: none;
}
._2Jtxm ._2kL0S,
._2Jtxm ._2JvrO {
  background-color: #e7eaf3;
  color: var(--text-normal);
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  line-height: 2.5rem;
  display: inline-flex;
  -webkit-transform-origin: center;
          transform-origin: center;
  align-items: center;
  justify-content: center;
}
._2kL0S, ._2JvrO {
  transition: background-color 250ms ease;
}
._2Jtxm ._1hzhf {
  color: var(--text-normal);
  position: absolute;
  top: 120%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
._2Jtxm ._1ixJ3 {
  display: block;
}
._2Jtxm._2ZUAI ._2JvrO {
  background-color: #4db193;
  color: white;
  -webkit-animation: _3SDnc 400ms ease-in-out forwards;
          animation: _3SDnc 400ms ease-in-out forwards;
}
._2Jtxm._2ZUAI ._2JvrO:hover {
  background-color: #42997f;
}
._2Jtxm._35Ago ._2kL0S {
  background-color: #387cf6;
  color: white;
  -webkit-animation: _JAh3L 400ms ease forwards;
          animation: _JAh3L 400ms ease forwards;
}
._2Jtxm._35Ago ._2kL0S:hover {
  background-color: #316ddb;
}
._2Jtxm._2ZUAI::before,
._2Jtxm._35Ago::before,
._2Jtxm._1CcaK::before {
  background-position: left bottom;
}
._2Jtxm._1CcaK ._2JvrO {
  color: white;
  background-color: #bb392d;
  -webkit-animation: _1ujce 350ms ease-in-out forwards;
          animation: _1ujce 350ms ease-in-out forwards;
}
._2_g61 {
  padding: 1rem;
}
._3uApM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
._2pGos {
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
._3CDiP {
  border: 1px solid #d1d9eb;
}
._3CDiP:hover {
  background-color: #f3f7ff;
  box-shadow: 0px 3px 6px 0 rgba(190, 201, 228, 0.3);
}
._hsN1w {
  background-color: #397cf6;
  border: 1px solid #397cf6;
  color: white;
}
._hsN1w:hover {
  background-color: #316ddb;
  border: 1px solid #316ddb;
  box-shadow: 0px 3px 6px 0 rgba(57, 124, 246, 0.3);
}
._2pGos._2RWmX {
  pointer-events: none;
  -webkit-filter: opacity(0.7);
          filter: opacity(0.7);
  cursor: default;
}

@-webkit-keyframes _JAh3L {
  0% { -webkit-transform: scale(1); transform: scale(1); }
  10% { -webkit-transform: scale(0.975); transform: scale(0.975); }
  40% { -webkit-transform: scale(1.4); transform: scale(1.4); }
  50%, 100% { -webkit-transform: scale(1.2); transform: scale(1.2); }
}

@keyframes _JAh3L {
  0% { -webkit-transform: scale(1); transform: scale(1); }
  10% { -webkit-transform: scale(0.975); transform: scale(0.975); }
  40% { -webkit-transform: scale(1.4); transform: scale(1.4); }
  50%, 100% { -webkit-transform: scale(1.2); transform: scale(1.2); }
}
@-webkit-keyframes _3SDnc {
  0% { -webkit-transform: scale(1.2); transform: scale(1.2); }
  35%, 100% { -webkit-transform: scale(1); transform: scale(1); }
}
@keyframes _3SDnc {
  0% { -webkit-transform: scale(1.2); transform: scale(1.2); }
  35%, 100% { -webkit-transform: scale(1); transform: scale(1); }
}
@-webkit-keyframes _1ujce {
  0% { -webkit-transform: translateX(0); transform: translateX(0); }
  15% { -webkit-transform: translateX(0.2rem); transform: translateX(0.2rem); }
  30%, 60% { -webkit-transform: translateX(-0.4rem); transform: translateX(-0.4rem); }
  45%, 75% { -webkit-transform: translateX(0.4rem); transform: translateX(0.4rem); }
  90% { -webkit-transform: translateX(-0.2rem); transform: translateX(-0.2rem); }
  100% { -webkit-transform: translateX(0); transform: translateX(0); }
}
@keyframes _1ujce {
  0% { -webkit-transform: translateX(0); transform: translateX(0); }
  15% { -webkit-transform: translateX(0.2rem); transform: translateX(0.2rem); }
  30%, 60% { -webkit-transform: translateX(-0.4rem); transform: translateX(-0.4rem); }
  45%, 75% { -webkit-transform: translateX(0.4rem); transform: translateX(0.4rem); }
  90% { -webkit-transform: translateX(-0.2rem); transform: translateX(-0.2rem); }
  100% { -webkit-transform: translateX(0); transform: translateX(0); }
}

:root, :host {
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Solid';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Regular';
  --fa-font-light: normal 300 1em/1 'Font Awesome 6 Light';
  --fa-font-thin: normal 100 1em/1 'Font Awesome 6 Thin';
  --fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone';
  --fa-font-sharp-solid: normal 900 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-regular: normal 400 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-light: normal 300 1em/1 'Font Awesome 6 Sharp';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box; }

.svg-inline--fa {
  display: inline-block;
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -.125em; }
  .svg-inline--fa.fa-2xs {
    vertical-align: 0.1em; }
  .svg-inline--fa.fa-xs {
    vertical-align: 0em; }
  .svg-inline--fa.fa-sm {
    vertical-align: -0.07143em; }
  .svg-inline--fa.fa-lg {
    vertical-align: -0.2em; }
  .svg-inline--fa.fa-xl {
    vertical-align: -0.25em; }
  .svg-inline--fa.fa-2xl {
    vertical-align: -0.3125em; }
  .svg-inline--fa.fa-pull-left {
    margin-right: 0.3em;
    margin-right: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .svg-inline--fa.fa-pull-right {
    margin-left: 0.3em;
    margin-left: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .svg-inline--fa.fa-li {
    width: 2em;
    width: var(--fa-li-width, 2em);
    top: 0.25em; }
  .svg-inline--fa.fa-fw {
    width: 1.25em;
    width: var(--fa-fw-width, 1.25em); }

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.fa-layers-text, .fa-layers-counter {
  display: inline-block;
  position: absolute;
  text-align: center; }

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -.125em;
  width: 1em; }
  .fa-layers svg.svg-inline--fa {
    -webkit-transform-origin: center center;
            transform-origin: center center; }

.fa-layers-text {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
          transform-origin: center center; }

.fa-layers-counter {
  background-color: #ff253a;
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: 1em;
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: #fff;
  color: var(--fa-inverse, #fff);
  line-height: 1;
  line-height: var(--fa-counter-line-height, 1);
  max-width: 5em;
  max-width: var(--fa-counter-max-width, 5em);
  min-width: 1.5em;
  min-width: var(--fa-counter-min-width, 1.5em);
  overflow: hidden;
  padding: 0.25em 0.5em;
  padding: var(--fa-counter-padding, 0.25em 0.5em);
  right: 0;
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: 0;
  top: var(--fa-top, 0);
  -webkit-transform: scale(0.25);
  -webkit-transform: scale(var(--fa-counter-scale, 0.25));
          transform: scale(0.25);
          transform: scale(var(--fa-counter-scale, 0.25));
  -webkit-transform-origin: top right;
          transform-origin: top right; }

.fa-layers-bottom-right {
  bottom: 0;
  bottom: var(--fa-bottom, 0);
  right: 0;
  right: var(--fa-right, 0);
  top: auto;
  -webkit-transform: scale(0.25);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(0.25);
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right; }

.fa-layers-bottom-left {
  bottom: 0;
  bottom: var(--fa-bottom, 0);
  left: 0;
  left: var(--fa-left, 0);
  right: auto;
  top: auto;
  -webkit-transform: scale(0.25);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(0.25);
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left; }

.fa-layers-top-right {
  top: 0;
  top: var(--fa-top, 0);
  right: 0;
  right: var(--fa-right, 0);
  -webkit-transform: scale(0.25);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(0.25);
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: top right;
          transform-origin: top right; }

.fa-layers-top-left {
  left: 0;
  left: var(--fa-left, 0);
  right: auto;
  top: 0;
  top: var(--fa-top, 0);
  -webkit-transform: scale(0.25);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(0.25);
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: top left;
          transform-origin: top left; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(2em * -1);
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: 2em;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: #eee;
  border-color: var(--fa-border-color, #eee);
  border-radius: 0.1em;
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: solid;
  border-style: var(--fa-border-style, solid);
  border-width: 0.08em;
  border-width: var(--fa-border-width, 0.08em);
  padding: 0.2em 0.25em 0.15em;
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: 0.3em;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: 0.3em;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  -webkit-animation-name: fa-beat;
          animation-name: fa-beat;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: 0s;
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: normal;
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: 1s;
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: infinite;
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: ease-in-out;
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-bounce {
  -webkit-animation-name: fa-bounce;
          animation-name: fa-bounce;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: 0s;
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: normal;
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: 1s;
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: infinite;
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
          animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.fa-fade {
  -webkit-animation-name: fa-fade;
          animation-name: fa-fade;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: 0s;
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: normal;
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: 1s;
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: infinite;
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-beat-fade {
  -webkit-animation-name: fa-beat-fade;
          animation-name: fa-beat-fade;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: 0s;
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: normal;
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: 1s;
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: infinite;
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  -webkit-animation-name: fa-flip;
          animation-name: fa-flip;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: 0s;
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: normal;
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: 1s;
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: infinite;
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: ease-in-out;
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-shake {
  -webkit-animation-name: fa-shake;
          animation-name: fa-shake;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: 0s;
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: normal;
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: 1s;
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: infinite;
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: linear;
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: linear;
          animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: 0s;
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: normal;
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 2s;
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
          animation-duration: 2s;
          animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: infinite;
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: linear;
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: linear;
          animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: normal;
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: 1s;
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: infinite;
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: steps(8);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
          animation-timing-function: steps(8);
          animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    -webkit-animation-delay: -1ms;
            animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
            animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s; } }

@-webkit-keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    -webkit-transform: scale(1.25);
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(1.25);
            transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    -webkit-transform: scale(1.25);
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(1.25);
            transform: scale(var(--fa-beat-scale, 1.25)); } }

@-webkit-keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(1.1, 0.9) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-0.5em);
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(0.9, 1.1) translateY(-0.5em);
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(1.05, 0.95) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(-0.125em);
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(-0.125em);
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(1.1, 0.9) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-0.5em);
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(0.9, 1.1) translateY(-0.5em);
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(1.05, 0.95) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(-0.125em);
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(-0.125em);
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@-webkit-keyframes fa-fade {
  50% {
    opacity: 0.4;
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-fade {
  50% {
    opacity: 0.4;
    opacity: var(--fa-fade-opacity, 0.4); } }

@-webkit-keyframes fa-beat-fade {
  0%, 100% {
    opacity: 0.4;
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.125);
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(1.125);
            transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: 0.4;
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.125);
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(1.125);
            transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -180deg);
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(0, 1, 0, -180deg);
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -180deg);
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(0, 1, 0, -180deg);
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@-webkit-keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg); }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg); }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg); }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg); }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg); }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg); }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg); }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg); }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg); }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg); }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg); }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg); }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

.fa-rotate-by {
  -webkit-transform: rotate(none);
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
          transform: rotate(none);
          transform: rotate(var(--fa-rotate-angle, none)); }

.fa-stack {
  display: inline-block;
  vertical-align: middle;
  height: 2em;
  position: relative;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: auto;
  z-index: var(--fa-stack-z-index, auto); }

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em; }

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em; }

.fa-inverse {
  color: #fff;
  color: var(--fa-inverse, #fff); }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.svg-inline--fa .fa-primary {
  fill: currentColor;
  fill: var(--fa-primary-color, currentColor);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa .fa-secondary {
  fill: currentColor;
  fill: var(--fa-secondary-color, currentColor);
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: black; }

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: #fff;
  color: var(--fa-inverse, #fff); }

